const data = {
  platforms: [
    { id: 1, type: "facebook",  name: "nathanf", followers: 1987, difference: 12 },
    { id: 2, type: "twitter",  name: "nathanf", followers: 1044, difference: 99 },
    { id: 3, type: "instagram", name: "realnathanf", followers: 11012, difference: 1099 },
    { id: 4, type: "youtube",  name: "Nathan F.", followers: 8239, difference: -144 },
  ],
  events: [
    { id: 5, platform: "facebook", type: "Page views", amount: 87, modifier: 3 },
    { id: 6, platform: "facebook", type: "Likes", amount: 52, modifier: -2 },
    { id: 7, platform: "instagram", type: "Likes", amount: 5462, modifier: 2257 },
    { id: 8, platform: "instagram", type: "Profile Views", amount: 52150, modifier: 1375 },
    { id: 9, platform: "twitter", type: "Retweets", amount: 117, modifier: 303 },
    { id: 10, platform: "twitter", type: "Likes", amount: 507, modifier: 553 },
    { id: 11, platform: "youtube", type: "Likes", amount: 107, modifier: -19 },
    { id: 12, platform: "youtube", type: "Total Views", amount: 1407, modifier: -12 },
  ],
};

export default data;