import React from 'react'
import './secondcard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp } from '@fortawesome/free-solid-svg-icons'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'

const Secondcard = (props) => {

	const up = <FontAwesomeIcon icon={faSortUp} />
	const down = <FontAwesomeIcon icon={faSortDown} />

	const { secondcard } = props;

	return (
		<div className="fullcard">
			<div className="firstDiv">
				<p className="type">{secondcard.type}</p>
				<div className="sc-2">
					<div className={secondcard.platform}></div></div>
			</div>
			<div className="secondDiv">
				<h1 className="number">{secondcard.amount > 10000 ? Math.round(secondcard.amount / 1000) + "K" : secondcard.amount}</h1>
				{
				secondcard.modifier > 0 
				? 
				<p class="up-changed">{up} {secondcard.modifier}%</p> 
				: 
				<p class="down-changed">{down} {Math.abs(secondcard.modifier)}%</p> 
				}
			</div>
		</div>
	)
}

export default Secondcard