import './App.css';
import Maincard from './components/Maincard'
import Secondcard from './components/Secondcard'
import data from "./sample-data"

function App() {

	function formatNumber(num) {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
	};

	let allFollowers = data.platforms[0].followers + data.platforms[1].followers + data.platforms[2].followers + data.platforms[3].followers;

	return (
		<div className="mainDiv">
			<h1 className="title">Social Media Dashboard</h1>
			<h4 style={{marginTop: "10px"}}>Total Followers: {formatNumber(allFollowers)}</h4>
			<div className="App">
				{data.platforms.map((maincard) => (
					<Maincard key={maincard.id} maincard={maincard} />))}
			</div>
			<div>
				<h2 className="overview">Overview - Today</h2>
			</div>
			<div className="cards2">
				{data.events.map((secondcard) => (
					<Secondcard key={secondcard.id} secondcard={secondcard} />))}
			</div>
		</div>
	);

}

export default App;