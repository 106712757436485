import React from "react";
import "./maincard.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp } from '@fortawesome/free-solid-svg-icons'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'


const Maincard = (props) => {
  
  const up = <FontAwesomeIcon icon={faSortUp} />
  const down = <FontAwesomeIcon icon={faSortDown} />

  const { maincard } = props;

  console.log(maincard)

  return (
    <div className="bigcard">
			<div className="mc-1">
      <div className={maincard.type}></div></div>
      <div className="card">
        <p className="username">
          <span className={maincard.type}></span>@{maincard.name}{" "}
        </p>
        <h1 className="followers-number">{maincard.followers > 10000 ? Math.round(maincard.followers / 1000) + "K" : maincard.followers}</h1>
        {(maincard.type === "youtube") ? <h5 className="followers-text">Subscribers</h5> : <h5 className="followers-text">Followers</h5>}
        {maincard.difference > 0 
        ? 
        <p class="up-changed">{up} {maincard.difference} today</p> 
        : 
        <p class="down-changed">{down} {Math.abs(maincard.difference)} today</p>
        }
      </div>
    </div>
  );
};

export default Maincard;
